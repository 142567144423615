<template>
    <div>
        <div>
            {{ label }}
        </div>
        <div class="by-count-content">
            Total : {{ (Math.round(getCount * 100) / 100) }}
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'TotalRayons',
    props: {},
    data() {
        return {}
    },
    computed: {
        getCount() {
            return this.params.count
        },
        label() {
            return this.params.label
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.by-count-content {
    margin-top: 7px;
    font-style: italic!important;
    font-size: 12px;
    color: #8f6c9f;
}
</style>
